import React from "react";
import "./Header.css";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../image/tattQr.png";

const Header = () => {
  // const token = localStorage.getItem("token");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const token = query.get("token");
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      // navigate("./");
      window.location.href = `https://www.tattqr.com/`;
      // window.location.href = `http://localhost:3000/`;
    } catch (error) {
      console.log("error is", error);
    }
  };
  return (
    <div className="navbar">
      <div className="logoDiv">
        <img src={Logo} className="logo" alt="Logo" />
      </div>
      {token ? (
        <button onClick={handleLogout}>
          <i className="fa fa-fw fa-user desktop-icon"></i>
          <i className="fa fa-sign-out mobile-icon" aria-hidden="true"></i>
          <span className="button-text">Logout</span>
        </button>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default Header;
