import React, { useState } from "react";
import QRCode from "qrcode.react";
import axios from "axios";
import "./QRCodeGenerator.css";
import { BaseUrl } from "../shared/BaseUrl";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

const QRCodeGenerator = () => {
  const [text, setText] = useState("www.tq2.ai/m");
  const [qrText, setQrText] = useState("");
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const token = query.get("token");
  const handleGenerate = async () => {
    setLoading(true); // Show spinner
    try {
      const response = await axios.post(
        `${BaseUrl}/api/qrcode/qrcode`,
        { text }, // Payload
        { headers: { Authorization: `Bearer ${token}` } } // Headers with Authorization
      );
      // console.log("res is", { response });
      setQRCodeURL(response.data.qrCodeURL);
      setQrText(response.data.text);
    } catch (error) {
      console.error("Error generating QR code", error);
      if (error.response.data.status === 400) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  return (
    <div className="qr-code-generator">
      <div className="generator-container">
        <input
          style={{
            borderRadius: "2rem",
            borderBottom: "2px solid rgb(243, 181, 98)",
            borderTop: "2px solid rgb(243, 181, 98)",
            // border: "2px solid rgb(243, 181, 98)",
            // width: "100%",
          }}
          className="qr-code-gen-input"
          type="text"
          placeholder="Copy URL from above and paste here..."
          value={text}
          // onChange={(e) => setText(e.target.value)}
        />
        <button className="qr-code-gen-button" onClick={handleGenerate}>
          {loading ? (
            <Spin
              tip="Generating..."
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
              }}
            />
          ) : (
            "Generate QR"
          )}
        </button>
        {qrCodeURL && (
          <div className="qr-code-image">
            {
              <QRCode
                className="qr-code"
                value={qrText}
                level={"L"}
                size={175}
                style={{ marginBottom: "1rem" }}
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default QRCodeGenerator;
