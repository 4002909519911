import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import axios from "axios";
import "./QRCodeGenerator.css";
import { BaseUrl } from "../shared/BaseUrl";
import { toast } from "react-toastify";
import "./Content.css";
import { Spin, Select, Radio } from "antd";
import { Option } from "antd/es/mentions";
import { useLocation } from "react-router-dom";

const QRCodeGenerator = () => {
  const [text, setText] = useState("");
  const [label, setLabel] = useState("");
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState(); // State for category
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState("text"); // State to track radio button selection

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const token = query.get("token");
  console.log({ token });
  useEffect(() => {
    const fetchQr = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log({ response });
        setQRCodeURL(response.data._id);
      } catch (error) {
        console.error("Login error:", error.message);
      }
    };
    fetchQr();
  }, [token]);

  const handleGenerate = async () => {
    setLoading(true); // Show spinner
    try {
      const formData = new FormData();
      formData.append("file", file); // Append file to FormData

      // JSON object with text, qrCodeURL, and category
      const data = {
        text: text,
        qrCodeId: qrCodeURL,
        label: label,
        category: inputType === "content" ? category : null, // Send category only when inputType is 'content'
      };

      const response = await axios.post(
        `${BaseUrl}/api/content/content`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          params: data,
        }
      );

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log({ response });
    } catch (error) {
      console.error("Error generating QR code", error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  return (
    <div className="qr-code-generator-container">
      <div className="generator-containers">
        <h2 style={{ color: "rgb(243, 181, 98)" }}>Upload content</h2>
        <br />
        {/* Radio buttons for selecting input type */}
        <Radio.Group
          onChange={(e) => setInputType(e.target.value)}
          value={inputType}
          style={{ marginBottom: "1rem" }}
          className="radio-group"
        >
          <Radio style={{ color: "white" }} value="text">
            Text
          </Radio>
          <Radio style={{ color: "white" }} value="content">
            Content
          </Radio>
        </Radio.Group>

        {/* Conditionally show text input or category dropdown */}
        {inputType === "text" ? (
          <input
            style={{
              borderRadius: "2rem",
              borderBottom: "2px solid rgb(243, 181, 98)",
              borderTop: "2px solid rgb(243, 181, 98)",
            }}
            className="qr-code-generator-input"
            type="text"
            placeholder="Enter text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            disabled={file !== null} // Disable text input if a file is selected
          />
        ) : (
          <Select
            showSearch
            style={{
              width: "100%",
              marginBottom: "1rem",
              borderRadius: "2rem",
            }}
            placeholder="Select a category"
            allowClear
            optionFilterProp="children"
            onChange={(value) => setCategory(value)} // Update state on change
            value={category} // Bind to category state
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="Personal">Personal</Option>
            <Option value="Family">Family</Option>
            <Option value="Party">Party</Option>
            <Option value="Work">Work</Option>
            <Option value="Other">Other</Option>
          </Select>
        )}

        {/* Label input */}
        <input
          style={{
            borderRadius: "2rem",
            borderBottom: "2px solid rgb(243, 181, 98)",
            borderTop: "2px solid rgb(243, 181, 98)",
          }}
          className="qr-code-generator-input"
          type="text"
          placeholder="Enter Label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />

        {/* File upload */}
        <label
          htmlFor="file-upload"
          className="custom-file-upload-content"
          // disabled={text !== ""}
        >
          Choose File
          <input
            id="file-upload"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            disabled={text !== ""}
            style={{ display: "none" }}
          />
        </label>
        {file && <span className="file-name">{file.name}</span>}

        {/* Upload button */}
        <button
          className="qr-code-generator-button"
          onClick={handleGenerate}
          style={{ marginBottom: "3rem" }}
        >
          {loading ? (
            <Spin
              tip="Uploading..."
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
              }}
            />
          ) : (
            "Upload"
          )}
        </button>
      </div>
    </div>
  );
};

export default QRCodeGenerator;
