import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Table,
  Layout,
  Spin,
  Checkbox,
  Modal,
  Space,
  Button,
  Select,
  Image,
} from "antd";
import axios from "axios";
import { BaseUrl } from "../shared/BaseUrl";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import { DeleteTwoTone, RemoveRedEyeTwoTone } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const { Content } = Layout;
const { Option } = Select;

export const Dashboard = () => {
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [qrId, setQrId] = useState();
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentContentId, setCurrentContentId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [selectedType, setSelectedType] = useState("audio"); // State for selected content type
  const [selectedCategory, setSelectedCategory] = useState("All"); // State for selected category
  // const token = localStorage.getItem("token");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const token = query.get("token");

  const qrCodeRef = useRef();

  useLayoutEffect(() => {
    const fetchQr = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setQRCodeURL(response.data.text);
        setQrId(response.data._id);
      } catch (error) {
        console.error("Login error:", error.message);
      }
    };
    fetchQr();
  }, [token]);

  useEffect(() => {
    if (qrId) {
      const fetchContents = async () => {
        try {
          const response = await axios.get(
            `${BaseUrl}/api/content/contents/${qrId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setContents(response?.data?.contents);
          setCurrentContentId(response?.data?.currentContent?._id);
          setLoading(false);
        } catch (error) {
          console.error("Fetch content error:", error.message);
          setLoading(false);
        }
      };
      fetchContents();
    }
  }, [qrId, token]);

  const handleContentTypeChange = (value) => {
    setSelectedType(value);
  };

  const handleCheckboxChange = async (checked, record) => {
    if (checked) {
      setIsModalOpen(true);
      setCurrentContentId(record._id);
    } else {
      setCurrentContentId(null);
    }
  };

  const handleOk = async () => {
    const response = await axios.post(
      `${BaseUrl}/api/content/set/${currentContentId}`,
      { qrId: qrId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 3000,
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (contentId) => {
    try {
      const response = await axios.delete(
        `${BaseUrl}/api/content/delete/${contentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setContents(contents.filter((item) => item._id !== contentId));
    } catch (error) {
      console.error("Delete content error:", error.message);
    }
  };

  const handleDeleteOk = () => {
    if (contentToDelete) {
      handleDelete(contentToDelete);
      setIsDeleteModalOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showModal = (content) => {
    setModalContent(content);
    setIsModalVisible(true);
  };

  // Handler to close the modal
  const handleDetailCancel = () => {
    setIsModalVisible(false);
    setModalContent(null);
  };

  const columns = [
    {
      title: "ID",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      render: (text) => (text ? text : <b>N/A</b>),
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      render: (label) => (label ? <b> {label}</b> : <b>N/A</b>),
    },
    {
      title: "Upload Time",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "View",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Image
            key={record._id}
            src={record.contentUrl}
            alt={`N/A`}
            width={50}
            style={{ marginRight: "10px" }}
            preview={{
              src: record.contentUrl,
            }}
          />
        </Space>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => (
        <Space size="middle">
          <DeleteTwoTone
            color="warning"
            danger
            onClick={() => {
              setIsDeleteModalOpen(true);
              setContentToDelete(record._id);
            }}
          />
        </Space>
      ),
    },
    {
      title: "Select",
      key: "checkbox",
      render: (text, record) => (
        <Checkbox
          checked={record._id === currentContentId}
          onChange={(e) => handleCheckboxChange(e.target.checked, record)}
        />
      ),
    },
  ];

  const filteredContents =
    selectedCategory === "All"
      ? contents // If "All" is selected, use the entire contents array
      : contents.filter(
          (content) =>
            content.contentType === selectedType &&
            content.category === selectedCategory
        );
  const handleDownloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr_code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  console.log({ modalContent });
  return (
    <Layout style={{ padding: "0 24px 24px", background: "transparent" }}>
      {loading ? (
        <Spin
          tip="Loading..."
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        <>
          <div
            style={{
              // marginTop: "2rem",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "2rem",
              // overflow: "scroll",
              // border: "1px solid #d9d9d9",
              backgroundColor: "rgb(243, 181, 98)",
              borderRadius: 4,
              padding: 24,
              // margin: "0 0 24px 0",
            }}
          >
            <div
              ref={qrCodeRef}
              style={{
                background: "#fff",
                padding: "24px",
                display: "inline-block",
              }}
            >
              <QRCode value={qrCodeURL} size={200} />
            </div>
            <button
              // className="qr-code-generator-button"
              type="primary"
              onClick={handleDownloadQRCode}
              style={{
                marginTop: "1rem",
                backgroundColor: "rgb(12, 12, 12)",
                width: "30%",

                // border: "2px solid rgb(117, 117, 117)",
              }}
            >
              <i class="fa fa-download" aria-hidden="true"></i>
            </button>
          </div>

          <Content style={{ marginTop: "24px" }}>
            <Select
              defaultValue="audio"
              style={{
                width: 200,
                marginTop: "24px",
                marginRight: "16px",
              }}
              onChange={handleContentTypeChange}
            >
              <Option value="audio">Audio</Option>
              <Option value="video">Video</Option>
              <Option value="image">Image</Option>
              <Option value="text">Text</Option>
            </Select>

            {/* New category dropdown */}
            <Select
              defaultValue="All"
              style={{
                width: 200,
                marginTop: "24px",
                marginLeft: "16px", // for some spacing
              }}
              onChange={(value) => setSelectedCategory(value)}
            >
              <Option value="All">All</Option>
              <Option value="Work">Work</Option>
              <Option value="Party">Party</Option>
              <Option value="Other">Other</Option>
              <Option value="Family">Family</Option>
              <Option value="Personal">Personal</Option>
            </Select>

            <Table
              style={{
                marginTop: "2rem",
                backgroundColor: "#fff",
                overflow: "scroll",
              }}
              columns={columns}
              dataSource={filteredContents}
              rowKey={(record) => record._id}
            />
          </Content>

          {/* Modal to confirm the selection */}
          <Modal
            title="Confirm Selection"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Set Content"
          >
            <p>
              Are you sure you want to select this content as the default for
              your QR code?
            </p>
          </Modal>

          {/* Modal to confirm deletion */}
          <Modal
            title="Confirm Deletion"
            open={isDeleteModalOpen}
            onOk={handleDeleteOk}
            onCancel={handleDeleteCancel}
            okText="Delete"
            style={{ height: "200px" }}
          >
            <p>Are you sure you want to delete this content?</p>
          </Modal>
          <Modal
            title="Content View"
            open={isModalVisible}
            onCancel={handleDetailCancel}
            footer={null} // You can add custom footer buttons if needed
          >
            <iframe
              // src={
              //   "https://w7.pngwing.com/pngs/895/199/png-transparent-spider-man-heroes-download-with-transparent-background-free-thumbnail.png"
              // }
              src={modalContent?.contentUrl}
              style={{
                width: "100%",
                height: "400px",
                border: "none",
                display: "flex",
                justifyContent: "center",
              }}
              title="Content"
            />
          </Modal>
        </>
      )}
    </Layout>
  );
};

// import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// import { Table, Layout, Spin, Checkbox, Modal, Space, Button } from "antd";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import { BaseUrl } from "../shared/BaseUrl";
// import QRCode from "qrcode.react";
// import { toast } from "react-toastify";
// import {
//   DeleteOutlined,
//   DeleteTwoTone,
//   RemoveRedEyeTwoTone,
// } from "@mui/icons-material";
// import { useLocation } from "react-router-dom";

// const { Content } = Layout;

// export const Dashboard = () => {
//   const [qrCodeURL, setQRCodeURL] = useState("");
//   const [qrId, setQrId] = useState();
//   const [contents, setContents] = useState([]); // State to store fetched content
//   const [loading, setLoading] = useState(true); // State to manage loading spinner
//   const [currentContentId, setCurrentContentId] = useState(null); // State to store current content id
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete confirmation modal
//   const [contentToDelete, setContentToDelete] = useState(null); // State to store the content ID to be deleted
//   // const token = localStorage.getItem("token");

//   const useQuery = () => {
//     return new URLSearchParams(useLocation().search);
//   };
//   const query = useQuery();
//   const token = query.get("token");

//   const qrCodeRef = useRef();

//   console.log({ token });
//   useLayoutEffect(() => {
//     const fetchQr = async () => {
//       try {
//         const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         toast.success(response.data.message, {
//           position: "top-right",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//         });
//         setQRCodeURL(response.data.text);
//         setQrId(response.data._id);
//       } catch (error) {
//         console.error("Login error:", error.message);
//       }
//     };
//     fetchQr();
//   }, [token]);

//   useEffect(() => {
//     if (qrId) {
//       const fetchContents = async () => {
//         try {
//           const response = await axios.get(
//             `${BaseUrl}/api/content/contents/${qrId}`,
//             {
//               headers: { Authorization: `Bearer ${token}` },
//             }
//           );
//           setContents(response?.data?.contents); // Store the fetched content in state
//           setCurrentContentId(response?.data?.currentContent?._id); // Store current content id
//           setLoading(false); // Set loading to false after data is fetched
//         } catch (error) {
//           console.error("Fetch content error:", error.message);
//           setLoading(false); // Set loading to false in case of error
//         }
//       };
//       fetchContents();
//     }
//   }, [qrId, token]);

//   // Filter contents by type
//   const audioContents = contents.filter((item) => item.contentType === "audio");
//   const videoContents = contents.filter((item) => item.contentType === "video");
//   const imageContents = contents.filter((item) => item.contentType === "image");
//   const textContents = contents.filter((item) => item.contentType === "text");

//   const handleCheckboxChange = async (checked, record) => {
//     console.log({ record });
//     if (checked) {
//       setIsModalOpen(true);
//       setCurrentContentId(record._id);
//     } else {
//       setCurrentContentId(null); // Uncheck if already checked
//     }
//   };

//   const handleOk = async () => {
//     const response = await axios.post(
//       `${BaseUrl}/api/content/set/${currentContentId}`,
//       { qrId: qrId },
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     toast.success(response.data.message, {
//       position: "top-right",
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//     });
//     setIsModalOpen(false);
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const handleDelete = async (contentId) => {
//     try {
//       const response = await axios.delete(
//         `${BaseUrl}/api/content/delete/${contentId}`,
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       toast.success(response.data.message, {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       });
//       setContents(contents.filter((item) => item._id !== contentId));
//     } catch (error) {
//       console.error("Delete content error:", error.message);
//     }
//   };

//   // New function to handle the delete confirmation modal OK button
//   const handleDeleteOk = () => {
//     if (contentToDelete) {
//       handleDelete(contentToDelete);
//       setIsDeleteModalOpen(false);
//     }
//   };

//   const handleDeleteCancel = () => {
//     setIsDeleteModalOpen(false);
//   };

//   // Define columns for each table
//   const columns = [
//     {
//       title: "ID",
//       key: "index",
//       render: (text, record, index) => index + 1, // Render a simple counting number
//     },
//     {
//       title: "Text",
//       dataIndex: "text",
//       key: "text",
//       render: (text) => (text ? text : <b>N/A</b>), // Render "NA" for null or empty values
//     },
//     {
//       title: "Label",
//       dataIndex: "label",
//       key: "label",
//       render: (label) => (label ? <b> {label}</b> : <b>N/A</b>), // Render "NA" for null or empty values
//     },
//     {
//       title: "Upload Time",
//       dataIndex: "createdAt",
//       key: "createdAt",
//     },
//     {
//       title: "View",
//       key: "action",
//       render: (text, record) => (
//         <Space size="middle">
//           <RemoveRedEyeTwoTone
//             onClick={() => {
//               window.open(record.contentUrl, "_blank");
//             }}
//             color="success"
//           />
//           {/* <Button type="primary">View</Button> */}
//         </Space>
//       ),
//     },
//     {
//       title: "Delete",
//       key: "delete",
//       render: (text, record) => (
//         <Space size="middle">
//           <DeleteTwoTone
//             color="warning"
//             danger
//             onClick={() => {
//               setIsDeleteModalOpen(true); // Open the delete confirmation modal
//               setContentToDelete(record._id); // Store the content ID to be deleted
//             }}
//           />
//         </Space>
//       ),
//     },
//     {
//       title: "Select",
//       key: "checkbox",
//       render: (text, record) => (
//         <Checkbox
//           checked={record._id === currentContentId}
//           onChange={(e) => handleCheckboxChange(e.target.checked, record)}
//         >
//           {/* {record._id === currentContentId && (
//             <span style={{ marginLeft: 5, fontWeight: "bold" }}>
//               Current Content
//             </span>
//           )} */}
//         </Checkbox>
//       ), // Render a checkbox and check it if the id matches current content id
//     },
//   ];

//   const handleDownloadQRCode = () => {
//     const canvas = qrCodeRef.current.querySelector("canvas");
//     const pngUrl = canvas
//       .toDataURL("image/png")
//       .replace("image/png", "image/octet-stream");
//     const downloadLink = document.createElement("a");
//     downloadLink.href = pngUrl;
//     downloadLink.download = "QRCode.png";
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     document.body.removeChild(downloadLink);
//   };

//   return (
//     <Layout style={{ padding: "0 24px 24px", background: "transparent" }}>
//       {loading ? (
//         <Spin
//           tip="Loading..."
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100vh",
//           }}
//         />
//       ) : (
//         <div
//           style={{
//             marginTop: "2rem",
//             overflow: "scroll",
//             // border: "1px solid #d9d9d9",
//             backgroundColor: "rgb(243, 181, 98)",
//             borderRadius: 4,
//             padding: 24,
//             // margin: "0 0 24px 0",
//           }}
//         >
//           <div ref={qrCodeRef}>
//             <QRCode className="qr-code" value={qrCodeURL} />
//           </div>
//           <button
//             // className="qr-code-generator-button"
//             type="primary"
//             onClick={handleDownloadQRCode}
//             style={{
//               marginTop: "1rem",
//               backgroundColor: "rgb(12, 12, 12)",
//               width: "30%",

//               // border: "2px solid rgb(117, 117, 117)",
//             }}
//           >
//             <i class="fa fa-download" aria-hidden="true"></i>
//           </button>
//         </div>
//       )}

//       <Content style={{ margin: "24px 0" }}>
//         {loading ? (
//           <Spin
//             tip="Loading..."
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "100vh",
//             }}
//           />
//         ) : (
//           <>
//             <div>
//               <div style={{ backgroundColor: "rgb(243, 181, 98)" }}>
//                 <h2>Audio</h2>
//                 <Table
//                   columns={columns}
//                   dataSource={audioContents}
//                   pagination={false}
//                   rowKey="_id"
//                   style={{ backgroundColor: "#fff", overflow: "scroll" }}
//                   locale={{ emptyText: "No Audio Uploaded" }}
//                 />
//               </div>

//               <div style={{ backgroundColor: "rgb(243, 181, 98)" }}>
//                 <h2>Video</h2>
//                 <Table
//                   columns={columns}
//                   dataSource={videoContents}
//                   pagination={false}
//                   rowKey="_id"
//                   style={{ backgroundColor: "#fff", overflow: "scroll" }}
//                   locale={{ emptyText: "No Video Uploaded" }}
//                 />
//               </div>
//               <div style={{ backgroundColor: "rgb(243, 181, 98)" }}>
//                 <h2>Images</h2>
//                 <Table
//                   columns={columns}
//                   dataSource={imageContents}
//                   pagination={false}
//                   rowKey="_id"
//                   style={{ backgroundColor: "#fff", overflow: "scroll" }}
//                   locale={{ emptyText: "No Image Uploaded" }}
//                 />
//               </div>
//               <div style={{ backgroundColor: "rgb(243, 181, 98)" }}>
//                 <h2>Text</h2>
//                 <Table
//                   columns={columns}
//                   dataSource={textContents}
//                   pagination={false}
//                   rowKey="_id"
//                   style={{ backgroundColor: "#fff", overflow: "scroll" }}
//                   locale={{ emptyText: "No Text Uploaded" }}
//                 />
//               </div>
//             </div>
//           </>
//         )}
//       </Content>
//       <Modal
//         title="Change content"
//         open={isModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         okButtonProps={{
//           style: {
//             backgroundColor: "rgb(243, 181, 98)",
//             marginTop: "1rem",
//             marginLeft: "-1rem",
//           },
//         }}
//       >
//         <p>Are you sure you want change the current content</p>
//       </Modal>
//       <Modal
//         title="Confirm Delete"
//         open={isDeleteModalOpen}
//         onOk={handleDeleteOk}
//         onCancel={handleDeleteCancel}
//         okButtonProps={{
//           style: {
//             backgroundColor: "rgb(243, 181, 98)",
//             marginTop: "1rem",
//             marginLeft: "-1rem",
//           },
//         }}
//       >
//         <p>Are you sure you want to delete this content?</p>
//       </Modal>
//     </Layout>
//   );
// };
